$(function(){

    $('.hero').not('.slick-initialized').slick({
        arrows: true,
        dots: false,
        infinite: true,
        autoplay: true,
        cssEase: 'linear',
        speed: 900,
        autoplaySpeed: 4000,
        pauseOnHover: false,
        pauseOnFocus: false,
        adaptiveHeight: false,
    });
    
    var featproductslist = $('.feat-products-list');
    if(featproductslist.length>0 && typeof featproductslist!="undefined"){
        featproductslist.slick({
            slidesToShow: 3,
            slidesToScroll: 1,
            dots: false,
            arrows: true,
            centerMode: false,
            focusOnSelect: true,
            responsive: [{
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 2,
                        arrows: true
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 1,
                        arrows: true
                    }
                }
            ]
        });
    }

});
    